import { useState } from "react"
import Navbar from "../components/Navbar"
import Country from "./forms/Country"
import Email from "./forms/Email"
import FullName from "./forms/FullName"
import Password from "./forms/Password"
import PhoneNumber from "./forms/PhoneNumber"
import Sex from "./forms/Sex"
import Summary from "./forms/Summary"
import * as Yup from 'yup'
import ErrorSnackbar from "../components/ErrorSnackbar"
import { Alert, Slide, Snackbar } from "@mui/material"
import ConfirmPassword from "./forms/ConfirmPassword"
import { Navigate } from "react-router-dom"

const validationSchema = Yup.object({
    full_name: Yup
        .string()
        .min(3, 'Full name must have a minimum of 8 characters')
        .max(250, 'Full name must have a maximum of 250 characters'),
    sex: Yup
        .string()
        .lowercase()
        .oneOf(["male", "female"]),
    phone_number: Yup
        .string()
        .min(11, 'Invalid Number')
        .max(11, 'Invalid Number'),
    email: Yup
        .string()
        .lowercase()
        .matches(/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/, "Invalid Email address"),
    password: Yup
        .string()
        .min(8, "Password must contain 8 characters")
        .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}/, 'Password must contain: 1 number, 1 special character, 1 Uppercase letter'),
    password1: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
    
})

function Signup() {
    const [redirect, setRedirect] = useState(false)
    const [isSubmitting, setSubmitting] = useState(false)
    const [message, setMessage] = useState('')
    const [open, setOpen] = useState(false)
    const [page, setPage] = useState(0)
    const [formData, setFormData] = useState({
        full_name: '',
        email: '',
        sex: '',
        phone_number: '',
        country: '',
        password: '',
        password1: ''
    })

    function TransitionDown(props){
        return <Slide {...props} direction="up" />;
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
    
    // Click Next Button
    function handleClickNext() {
        if (page === 0) {
            validationSchema.isValid({
                full_name: formData.full_name
            })
            .then((valid) => {
                if(valid) {
                    setPage(page + 1)
                }
                else {
                    setMessage("Invalid Full Name")
                    setOpen(true)
                }
            })
        }else if (page === 1) {
            validationSchema.isValid({
                email: formData.email
            })
            .then((valid) => {
                if(valid) {
                    setPage(page + 1)
                }
                else {
                    setMessage("Invalid email address")
                    setOpen(true)
                }
            })
        }else if (page === 2) {
            validationSchema.isValid({
                sex: formData.sex
            })
            .then((valid) => {
                if(valid) {
                    setPage(page + 1)
                }
                else {
                    setMessage("Invalid entry")
                    setOpen(true)
                }
            })
        }else if (page === 3) {
            validationSchema.isValid({
                phone_number: formData.phone_number
            })
            .then((valid) => {
                if(valid) {
                    setPage(page + 1)
                }
                else {
                    setMessage("Phone number must have 11 digits")
                    setOpen(true)
                }
            })
        }else if (page === 4) {
            setPage(page + 1)
        }else if (page === 5) {
            validationSchema.isValid({
                password: formData.password,
            })
            .then((valid) => {
                if(valid) {
                    setPage(page + 1)
                }
                else {
                    setMessage("Password must contain: 1 number, 1 special character, 1 Uppercase letter")
                    setOpen(true)
                }
            })
        }
        else if (page === 6) {
            validationSchema.isValid({
                password: formData.password,
                password1: formData.password1,
            })
            .then((valid) => {
                if(valid) {
                    setPage(page + 1)
                }
                else {
                    setMessage("Passwords must match")
                    setOpen(true)
                }
            })
        }
    }
    // Click Previous button
    function handleClickPrevious() {
        setPage(page - 1)
    }

    // Click Submit button
    function handleSubmit(values){
        fetch('https://towmat69.pythonanywhere.com/users', {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(values, null, 2)
        })
        .then(response => response.json())
        .then((data) => {
            if (data.ok){
                setSubmitting(false)
                setRedirect(true)
            }else{
                setSubmitting(false)
                setMessage("Something wrong happened, Please try again")
                setOpen(true)
                
            }
        })
        .catch(function(error){
            setMessage("Server Error")
            setOpen(true)
            setSubmitting(false)
        })
    }

    if(redirect){
        return <Navigate to='/success' />
    }

    const activeComponent = () => {
        switch (page) {
            case 0:
                return <FullName formData={formData} setFormData={setFormData} />
            case 1:
                return <Email formData={formData} setFormData={setFormData} header={"2/7"} />
            case 2:
                return <Sex formData={formData} setFormData={setFormData} />
            case 3:
                return <PhoneNumber formData={formData} setFormData={setFormData} />
            case 4:
                return <Country formData={formData} setFormData={setFormData} />
            case 5:
                return <Password formData={formData} setFormData={setFormData} />
            case 6:
                return <ConfirmPassword formData={formData} setFormData={setFormData} />
            case 7:
                return <Summary formData={formData} />
        }
    }
    return(
        <>
        <Snackbar
            open={open} 
            autoHideDuration={3000} 
            onClose={handleClose}
            TransitionComponent={TransitionDown}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}  
            message={message}
            >
            <Alert 
                onClose={handleClose} 
                severity="error" 
                sx={{ width: '100%', color: 'red', backgroundColor: '#ffffff' }}
                >
                {message}
            </Alert>
        </Snackbar>
        <Navbar />

        <section className=" bg-black body-font lg:pt-20 container mx-auto h-screen w-full max-w-full object-contain">
            {activeComponent()}

            <div className="flex flex-col items-center md:flex-row md:justify-center md:space-x-2">
                {page > 0 ? 
                    <button
                    onClick={() => {handleClickPrevious()}}
                    aria-label=""
                    className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 bg-black  hover:text-white hover:outline-double"
                    >
                    Back
                    </button> : null}
                {page >= 0 && page <= 6  ?
                    <button
                    onClick={() => {handleClickNext()}}
                    className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 bg-black  hover:text-white hover:outline-double"
                >
                 Next 
                </button>: null}
                {page === 7  ?
                    <button
                    disabled={isSubmitting}
                    onClick={() => {handleSubmit(formData)}}
                    className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 bg-black  hover:text-white hover:outline-double"
                >
                 Submit
                </button>: null}
            </div>
        </section>
        </>

    )
}

export default Signup