function Footer() {
    return(
        <>
            <div className="bg-black px-4 pt-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen md:px-24 lg:px-8">
            <div className="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
                <div className="sm:col-span-2">
                <a
                    href="/"
                    aria-label="Go home"
                    title="interactive"
                    className="inline-flex items-center"
                >
                    <span className="text-xl font-bold tracking-wide text-white ">
                    Interactive
                    </span>
                </a>
                <div className="mt-6 lg:max-w-sm">
                    <p className="text-sm text-white">
                    interactive is a speech-to-text form built with{" "}
                    ReactJS and powered by Django Backend
                    </p>
                </div>
                </div>
                <div className="space-y-2 text-sm">
                <p className="text-base font-bold tracking-wide text-white">
                    Contacts
                </p>
                <div className="flex">
                    <p className="mr-1 text-white">Phone:</p>
                    <a
                    href="tel:07039073318"
                    title="Phone"
                    className="transition-colors duration-300 text-white hover:text-purple-800"
                    >
                    0703-907-3318
                    </a>
                </div>
                <div className="flex">
                    <p className="mr-1 text-white">Email:</p>
                    <a
                    href="mailto:akandevic@gmail.com"
                    title="email"
                    className="transition-colors duration-300 text-white hover:text-purple-800"
                    >
                    akandevic@gmail.com
                    </a>
                </div>
                <div className="flex">
                    <p className="mr-1 text-white">Website:</p>
                    <a
                    href="https://akande.com.ng"
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Our address"
                    title="Our address"
                    className="transition-colors duration-300 text-white hover:text-purple-800"
                    >
                    akande.com.ng
                    </a>
                </div>
                </div>
                <div>
                <span className="text-base font-bold tracking-wide text-white">
                    Social
                </span>
                <div className="flex items-center mt-1 space-x-3">
                    <a
                    href="https://twitter.com/akandeav"
                    className="text-white transition-colors duration-300 hover:text-purple-400"
                    >
                    <svg viewBox="0 0 24 24" fill="currentColor" className="h-5">
                        <path d="M24,4.6c-0.9,0.4-1.8,0.7-2.8,0.8c1-0.6,1.8-1.6,2.2-2.7c-1,0.6-2,1-3.1,1.2c-0.9-1-2.2-1.6-3.6-1.6 c-2.7,0-4.9,2.2-4.9,4.9c0,0.4,0,0.8,0.1,1.1C7.7,8.1,4.1,6.1,1.7,3.1C1.2,3.9,1,4.7,1,5.6c0,1.7,0.9,3.2,2.2,4.1 C2.4,9.7,1.6,9.5,1,9.1c0,0,0,0,0,0.1c0,2.4,1.7,4.4,3.9,4.8c-0.4,0.1-0.8,0.2-1.3,0.2c-0.3,0-0.6,0-0.9-0.1c0.6,2,2.4,3.4,4.6,3.4 c-1.7,1.3-3.8,2.1-6.1,2.1c-0.4,0-0.8,0-1.2-0.1c2.2,1.4,4.8,2.2,7.5,2.2c9.1,0,14-7.5,14-14c0-0.2,0-0.4,0-0.6 C22.5,6.4,23.3,5.5,24,4.6z" />
                    </svg>
                    </a>
                    <a
                    href="https://instagram.com/akandeav"
                    className="text-white transition-colors duration-300 hover:text-purple-400"
                    >
                    <svg viewBox="0 0 30 30" fill="currentColor" className="h-6">
                        <circle cx="15" cy="15" r="4" />
                        <path d="M19.999,3h-10C6.14,3,3,6.141,3,10.001v10C3,23.86,6.141,27,10.001,27h10C23.86,27,27,23.859,27,19.999v-10   C27,6.14,23.859,3,19.999,3z M15,21c-3.309,0-6-2.691-6-6s2.691-6,6-6s6,2.691,6,6S18.309,21,15,21z M22,9c-0.552,0-1-0.448-1-1   c0-0.552,0.448-1,1-1s1,0.448,1,1C23,8.552,22.552,9,22,9z" />
                    </svg>
                    </a>
                </div>
                </div>
            </div>
            <div className="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
                <p className="text-sm text-white">
                © Copyright 2023 Akande Victor Adeola. All rights reserved.
                </p>
                <ul className="flex flex-col mb-3 space-y-2 lg:mb-0 sm:space-y-0 sm:space-x-5 sm:flex-row">
                <li>
                    <a
                    href="https://github.com/akandeav"
                    className="text-sm text-white transition-colors duration-300 hover:text-purple-400"
                    >
                    Github
                    </a>
                </li>
                </ul>
            </div>
            </div>
        </>
    )
}

export default Footer