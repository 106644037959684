import { Snackbar, Alert, Slide } from "@mui/material"

function ErrorSnackbar({open, setOpen, message}) {
    function TransitionDown(props){
        return <Slide {...props} direction="up" />;
    }
    return(
        <>
            <Snackbar
                TransitionComponent={TransitionDown}
                anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}  
                open={open} autoHideDuration={5000} onClose={() => {setOpen(false)}}>
                <Alert 
                    onClose={() => {setOpen(false)}} 
                    severity="error" 
                    sx={{ width: '100%', color: 'red', backgroundColor: '#ffffff' }}
                    >
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default ErrorSnackbar