import { useEffect } from "react"

function TextToSpeech({text}) {
    const msg = new SpeechSynthesisUtterance()
    msg.text = text

    useEffect(() => {
        window.speechSynthesis.speak(msg)
    }, [msg])
    
}

export default TextToSpeech