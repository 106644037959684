function Summary({formData}){
    return(
        <>
        <div className="container px-5 pt-32 mx-auto lg:px-4 lg:py-4 text-white">
            <div className="container max-w-5xl px-4 py-12 mx-auto">
                <div className="grid gap-4 mx-4 sm:grid-cols-12">
                    <div className="col-span-12 sm:col-span-3">
                        <div className="text-center sm:text-left mb-14 before:block before:w-24 before:h-3 before:mb-5 before:mx-auto sm:before:mx-0 before:bg-white">
                            <h3 className="text-3xl font-semibold uppercase">Summary</h3>
                            <span className="text-sm font-bold tracking-wider text-gray-400">Here's everything you entered</span>
                        </div>
                    </div>
                    <div className="relative col-span-12 px-4 space-y-6 sm:col-span-9">
                        <div className="col-span-12 space-y-12 relative px-4 sm:col-span-8 sm:space-y-8 sm:before:absolute sm:before:top-2 sm:before:bottom-0 sm:before:w-0.5 sm:before:-left-3 before:bg-gray-700">
                            <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-white">
                                <h3 className="text-xl font-semibold tracking-wide">{formData.full_name}</h3>
                                <p className="text-xs tracking-wide uppercase text-gray-400">Full Name</p>
                                
                            </div>
                            <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-white">
                                <h3 className="text-xl font-semibold tracking-wide">{formData.email}</h3>
                                <p className="text-xs tracking-wide uppercase text-gray-400">Email</p>
                            </div>
                            <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-white">
                                <h3 className="text-xl font-semibold tracking-wide">{formData.sex}</h3>
                                <p className="text-xs tracking-wide uppercase text-gray-400">Sex</p>
                            </div>
                            <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-white">
                                <h3 className="text-xl font-semibold tracking-wide">{formData.phone_number}</h3>
                                <p className="text-xs tracking-wide uppercase text-gray-400">Phone Number</p>
                            </div>
                            <div className="flex flex-col sm:relative sm:before:absolute sm:before:top-2 sm:before:w-4 sm:before:h-4 sm:before:rounded-full sm:before:left-[-35px] sm:before:z-[1] before:bg-white">
                                <h3 className="text-xl font-semibold tracking-wide">{formData.country}</h3>
                                <p className="text-xs tracking-wide uppercase text-gray-400">Country</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                
            </div>
        </>
    )
}

export default Summary