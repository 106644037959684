import AudioRecorder from "../../components/AudioRecorder"

function Email({formData, setFormData, header}){
    return(
        <>
        <div className="container px-5 pt-32 mx-auto lg:px-4 lg:py-4">
                <div className="flex flex-col w-full mb-2 text-center sm:items-center space-y-4 ">
                <h1 className="text-lg font-bold tracking-tighter text-white lg:text-3xl md:text-xl">
                <span className="font-medium text-sm tracking-wide">{header}</span>
                    <br ></br>
                    What is your email?
                </h1>
                <br></br>
                <input 
                    type="text" 
                    placeholder="john.doe@gmail.com"
                    className="mx-auto text-center rounded-md lg:w-1/3"
                    onChange={(e) => {
                        setFormData({
                            ...formData,
                            email: e.target.value.toLowerCase()
                        })
                    }}
                    value={formData.email}
                    >   
                </input>
                <AudioRecorder formData={formData} setFormData={setFormData} page={1} />
                </div>
                
            </div>
        </>
    )
}

export default Email