import AudioRecorder from "../../components/AudioRecorder"

function PhoneNumber({formData, setFormData}){
    return(
        <>
        <div className="container px-5 pt-32 mx-auto lg:px-4 lg:py-4">
                <div className="flex flex-col w-full mb-2 text-center sm:items-center space-y-4 ">
                <h1 className="text-lg font-bold tracking-tighter text-white lg:text-3xl md:text-xl">
                <span className="font-medium text-sm tracking-wide">4/7</span>
                    <br></br>
                    What is your phone number?
                </h1>
                <br></br>
                <input 
                    type="text" 
                    placeholder="08001001000" 
                    className="mx-auto text-center rounded-md lg:w-1/3"
                    onChange={(e) => {
                        setFormData({
                            ...formData,
                            phone_number: e.target.value
                        })
                    }}
                    value={formData.phone_number}
                    >   
                </input>
                <AudioRecorder formData={formData} setFormData={setFormData} page={3} />
                </div>
                
            </div>
        </>
    )
}

export default PhoneNumber