import Footer from "../components/Footer"

function Missing() {
    return (
        <>
            <section className=" bg-black body-font lg:pt-20 container mx-auto h-screen w-full max-w-full object-contain">
                <div className="relative max-w-7xl mx-auto px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
                    <p className="text-lg md:text-4xk font-semibold text-white text-opacity-50 uppercase tracking-wide">404 Error</p>
                    <h1 className="mt-2 text-xl md:text-2xl font-extrabold text-gray-600 tracking-tight sm:text-5xl">
                    The Page you are looking for cannot be found
                    </h1>
                    <div className="flex flex-col items-center md:flex-row md:justify-center md:space-x-2 mt-4">
                        <a
                        href="/login"
                        className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 bg-black  hover:text-white hover:outline-double"
                        >
                        Signin
                        </a>
                        <a
                        href="/"
                        className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 bg-black  hover:text-white hover:outline-double"
                        >
                        Home
                        </a>
                    </div>
                </div>
                <Footer />
            </section>
        </>
    )
}

export default Missing