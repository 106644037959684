import { useState } from "react"
import Navbar from "../components/Navbar"
import Email from "./forms/Email"
import * as Yup from 'yup'
import { Alert, Slide, Snackbar } from "@mui/material"
import { Navigate } from "react-router-dom"
import SigninPassword from "./forms/SigninPassword"
import { useCookies } from "react-cookie"
import { useJwt } from "react-jwt"

const validationSchema = Yup.object({
    email: Yup
        .string()
        .lowercase()
        .matches(/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/, "Invalid Email address")
    
})

function Signin() {
    const [redirect, setRedirect] = useState(false)
    const [isSubmitting, setSubmitting] = useState(false)
    const [message, setMessage] = useState('')
    const [open, setOpen] = useState(false)
    const [page, setPage] = useState(0)
    const [ cookies, setCookie ] = useCookies(['']);
    const { isExpired } = useJwt(cookies['dtk']);
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    })

    function TransitionDown(props){
        return <Slide {...props} direction="up" />;
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
    
    // Click Next Button
    function handleClickNext() {
        if (page === 0) {
            validationSchema.isValid({
                email: formData.email
            })
            .then((valid) => {
                if(valid) {
                    setPage(page + 1)
                }
                else {
                    setMessage("Invalid email address")
                    setOpen(true)
                }
            })
        }
    }
    // Click Previous button
    function handleClickPrevious() {
        setPage(page - 1)
    }

    // Click Submit button
    function handleSubmit(values){
        fetch('https://towmat69.pythonanywhere.com/login', {
            method: 'POST',
            mode: 'cors',
            credentials: 'include',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(values, null, 2)
        })
        .then(response => response.json())
        .then((data) => {
            if (data.ok){
                setCookie('dtk', data.id, { 'path': '/', 'maxAge': 3600});
                setSubmitting(false)
                setRedirect(true)
            }else{
                setSubmitting(false)
                setMessage("Invalid Password")
                setOpen(true)
                
            }
        })
        .catch(function(error){
            setMessage("Server Error")
            setOpen(true)
            setSubmitting(false)
        })
    }

    if(redirect){
        return <Navigate to='/dashboard' />
    }

    if (!isExpired && cookies['dtk']){
        return <Navigate to='/' />
    }
    

    const activeComponent = () => {
        switch (page) {
            case 0:
                return <Email formData={formData} setFormData={setFormData} header={"Login to your account"} />
            case 1:
                return <SigninPassword formData={formData} setFormData={setFormData} />
        }
    }
    return(
        <>
        <Snackbar
            open={open} 
            autoHideDuration={3000} 
            onClose={handleClose}
            TransitionComponent={TransitionDown}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}  
            message={message}
            >
            <Alert 
                onClose={handleClose} 
                severity="error" 
                sx={{ width: '100%', color: 'red', backgroundColor: '#ffffff' }}
                >
                {message}
            </Alert>
        </Snackbar>
        <Navbar />

        <section className=" bg-black body-font lg:pt-20 container mx-auto h-screen w-full max-w-full object-contain">
            {activeComponent()}

            <div className="flex flex-col items-center md:flex-row md:justify-center md:space-x-2">
            {page === 1 ? 
                    <button
                    onClick={() => {handleClickPrevious()}}
                    aria-label=""
                    className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 bg-black  hover:text-white hover:outline-double"
                    >
                    Back
                    </button> : null}
                {page === 0 ?
                    <button
                    onClick={() => {handleClickNext()}}
                    className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 bg-black  hover:text-white hover:outline-double"
                >
                 Next 
                </button>: null}
                {page === 1  ?
                    <button
                    disabled={isSubmitting}
                    onClick={() => {handleSubmit(formData)}}
                    className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 bg-black  hover:text-white hover:outline-double"
                >
                 Signin
                </button>: null}
            </div>
        </section>
        </>

    )
}

export default Signin