import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Landing from './pages/Landing';
import Signup from './pages/Signup';
import Success from './pages/Success';
import Signin from './pages/Signin';
import Profile from './pages/Profile';
import Missing from './pages/Missing';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" exact element={<Landing />} />
      <Route path="/signup" exact element={<Signup />} />
      <Route path="/login" exact element={<Signin />} />
      <Route path="/success" exact element={<Success />} />
      <Route path="/dashboard" exact element={<Profile />} />
      <Route path="/dashboard" exact element={<Profile />} />
      <Route path="*" element={<Missing />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
