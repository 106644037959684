import { useState } from "react";
import { useCookies } from "react-cookie";
import { isExpired } from "react-jwt";
import { Navigate } from "react-router-dom";

export default function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [ cookies ] = useCookies([''])
    const [redirect, setRedirect] = useState(false);
    const  istokenExpired  = isExpired(cookies['dtk'])

    const logout = () => {
      document.cookie = "dtk=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      setRedirect(true)
    }
    if (redirect) return <Navigate to='/login' />
  
    return (
      <div className="bg-black w-full">
        <div className="px-4 py-5 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8">
          <div className="relative flex items-center justify-between">
            <div className="flex items-center">
              <a
                href="/"
                title="logo"
                className="inline-flex items-center mr-8"
              >
                <span className="ml-2 text-2xl font-bold tracking-wide text-gray-100 ">
                  Interactive
                </span>
              </a>
            </div>
            <ul className="items-center hidden space-x-4 lg:flex">
              {istokenExpired ?
              <>
              <li>
                <a
                  href="/login"
                  aria-label="Sign in"
                  title="Sign in"
                  className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:outline-double"
                >
                  Sign in
                </a>
              </li>
              <li>
                <a
                  href="/signup"
                  className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-black transition duration-200 bg-white hover:bg-black hover:text-white hover:outline-double"
                  aria-label="Sign up"
                  title="Sign up"
                >
                  Sign up
                </a>
              </li>
              </> : null}
              {!istokenExpired ?
              <>
              <li>
                <a
                  href="/dashboard"
                  aria-label="dash"
                  title="dash"
                  className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-gray-100 transition-colors duration-200 hover:outline-double"
                >
                  Dashboard
                </a>
              </li>
              <li>
                <button
                  onClick={() => {logout()}}
                  className="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-black transition duration-200 bg-white hover:bg-black hover:text-white hover:outline-double"
                  aria-label="Logout"
                  title="Logout"
                >
                  Logout
                </button>
              </li>
              </> : null}
            </ul>
            <div className="lg:hidden">
              <button
                aria-label="Open Menu"
                title="Open Menu"
                className="p-2 -mr-1 transition duration-200 rounded focus:outline-none focus:shadow-outline"
                onClick={() => setIsMenuOpen(true)}
              >
                <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                  <path
                    fill="currentColor"
                    d="M23,13H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,13,23,13z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,6H1C0.4,6,0,5.6,0,5s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,6,23,6z"
                  />
                  <path
                    fill="currentColor"
                    d="M23,20H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h22c0.6,0,1,0.4,1,1S23.6,20,23,20z"
                  />
                </svg>
              </button>
              {isMenuOpen && (
                <div className="absolute top-0 left-0 w-full">
                  <div className="p-5 bg-white border rounded shadow-sm">
                    <div className="flex items-center justify-between mb-4">
                      <div>
                        <a
                          href="/"
                          aria-label="Company"
                          title="Company"
                          className="inline-flex items-center"
                        >
                          
                          <span className="ml-2 text-xl font-bold tracking-wide text-gray-800 uppercase">
                            Interactive
                          </span>
                        </a>
                      </div>
                      <div>
                        <button
                          aria-label="Close Menu"
                          title="Close Menu"
                          className="p-2 -mt-2 -mr-2 transition duration-200 rounded hover:bg-gray-200 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
                          onClick={() => setIsMenuOpen(false)}
                        >
                          <svg className="w-5 text-gray-600" viewBox="0 0 24 24">
                            <path
                              fill="currentColor"
                              d="M19.7,4.3c-0.4-0.4-1-0.4-1.4,0L12,10.6L5.7,4.3c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l6.3,6.3l-6.3,6.3 c-0.4,0.4-0.4,1,0,1.4C4.5,19.9,4.7,20,5,20s0.5-0.1,0.7-0.3l6.3-6.3l6.3,6.3c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3 c0.4-0.4,0.4-1,0-1.4L13.4,12l6.3-6.3C20.1,5.3,20.1,4.7,19.7,4.3z"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <nav>
                      <ul className="space-y-4">
                      {istokenExpired ?
                      <>
                      <li>
                          <a
                            href="/login"
                            aria-label="Sign in"
                            title="Sign in"
                            className="font-medium tracking-wide text-gray-700 text-center transition-colors duration-200 hover:text-purple-400"
                          >
                            Sign in
                          </a>
                        </li>
                        <li>
                          <a
                            href="/signup"
                            className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-black transition duration-200 rounded shadow-md  hover:bg-black hover:text-white focus:shadow-outline focus:outline-none"
                            aria-label="Sign up"
                            title="Sign up"
                          >
                            Sign up
                          </a>
                        </li>
                        </>
                        : null}
                        {!istokenExpired ?
                        <li>
                          <li>
                          <a
                            href="/dashboard"
                            aria-label="dash"
                            title="dash"
                            className="font-medium tracking-wide text-gray-700 text-center transition-colors duration-200 hover:text-purple-400"
                          >
                            Dashboard
                          </a>
                        </li>
                          <button
                            onClick={() => {logout()}}
                            className="inline-flex items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-black transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
                            aria-label="Logout"
                            title="Logout"
                          >
                            Logout
                          </button>
                        </li>
                        : null}
                      </ul>
                    </nav>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };