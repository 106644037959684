function Hero(){
    return(
        <>
        <section className="text-black bg-black body-font lg:pt-20">
            <div className="container px-5 pt-32 mx-auto lg:px-4 lg:py-4">
                <div className="flex flex-col w-full mb-2 text-left md:text-center sm:items-center space-y-4 ">
                <h1 className="text-6xl font-bold tracking-tighter text-white lg:text-8xl md:text-7xl">
                    <span>We are building </span>
                    <br className="hidden lg:block"></br>
                    Interactive forms
                </h1>
                <br></br>
                <p className="mx-auto text-xl font-normal leading-relaxed text-gray-600 dark:text-gray-300 lg:w-2/3">
                    interactive is a speech-to-text form built with{" "}
                    ReactJS and powered by Django Backend
                </p>
                <a
                    href="/signup"
                    className="inline-flex sm:w-1/6 items-center justify-center h-12 mt-2 px-6 font-medium tracking-wide text-black transition duration-200 bg-white hover:bg-black hover:text-white hover:outline-double focus:shadow-outline focus:outline-none"
                    >
                    Get started
                </a>
                </div>
                
            </div>
            <div className="container flex flex-col items-center justify-center py-8 mx-auto rounded-lg md:p-1 p-3">
                <img
                className="object-cover object-center w-full mb-10 border-gray-200 dark:border-gray-900 g327 border rounded-lg shadow-md"
                alt="hero"
                src="https://images.unsplash.com/photo-1577563908411-5077b6dc7624?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
                ></img>
            </div>
        </section>
        </>
    )
}

export default Hero