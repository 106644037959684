import { Alert, IconButton, Slide, Snackbar } from "@mui/material"
import { FiMic } from 'react-icons/fi'
import { CgRecord } from 'react-icons/cg'
import axios from "axios"
import { useState } from "react"
import AudioAnalyser from "react-audio-analyser"

function AudioRecorder({formData, setFormData, page}) {
    const [audioSrc, setAudioSrc] = useState(null)
    const [status, setStatus] = useState("inactive")
    const [record, setRecord] = useState(false)
    const audioType = "audio/wav"
    const width = 0
    const height = 0


    const audioProps = {
        audioType,
        status,
        audioSrc,
        width,
        height,
        timeslice: 1000, // timeslice（https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder/start#Parameters）
        startCallback: (e) => {
            console.log("succ start", e)
        },
        pauseCallback: (e) => {
            console.log("succ pause", e)
        },
        stopCallback: (e) => {
            setAudioSrc(window.URL.createObjectURL(e))
            //
            console.log("succ stop", e)
            submitRecording(e)
        },
        onRecordCallback: (e) => {
            console.log("recording", e)
        },
        errorCallback: (err) => {
            console.log("error", err)
        }
    }

    function clickRecord() {
        //startRecording()
        setStatus("recording")
        setRecord(true)
    }

    
    function clickStop() {
        //stopRecording()
        setStatus("inactive")
        setRecord(false)
    }
    
    
    function submitRecording(blob) {
        console.log('audio', blob)

        axios.post(
            'https://towmat69.pythonanywhere.com/transcript', 
            {
                data: blob
            },
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            )
            .then(function (response){
                if (page === 0) {
                    setFormData({
                        ...formData,
                        full_name: response.data.data
                    })
                }else if (page === 1) {
                    setFormData({
                        ...formData,
                        email: response.data.data
                    })
                }else if (page === 2) {
                    setFormData({
                        ...formData,
                        sex: response.data.data
                    })
                }else if (page === 3) {
                    setFormData({
                        ...formData,
                        phone_number: response.data.data
                    })
                }else if (page === 4) {
                    setFormData({
                        ...formData,
                        country: response.data.data
                    })
                }
                
            })
            .catch(function (error) {
                alert(error)
            })
        }
    return (
        <>
        
        <AudioAnalyser {...audioProps}>

        </AudioAnalyser>

        {!record ?
        <IconButton
            onClick={() => {clickRecord()}}
            >
        <FiMic className="text-white"/>  
        </IconButton>
        :null}
        {record ?
        <IconButton
            onClick={() => {clickStop(audioSrc)}}
            >
        <CgRecord className="text-red-600"/>  
        </IconButton>
        :null}
        </>
    )
}

export default AudioRecorder