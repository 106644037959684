import { useState } from "react";
import { useCookies } from "react-cookie";
import { isExpired } from "react-jwt";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import { Navigate } from "react-router-dom";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import TextToSpeech from "./TextToSpeech";

const queryClient = new QueryClient()


function Profile(){
    return (
        <QueryClientProvider client={queryClient}>
            <App />
        </QueryClientProvider>
    )
}

function App() {
    const [ cookies ] = useCookies([])
    const  istokenExpired  = isExpired(cookies['dtk'])

    const { isLoading, error, data } = useQuery(['homeData'], () => 
    fetch('https://towmat69.pythonanywhere.com/users', {
        credentials: 'include',
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': cookies['dtk']
        }
    }).then(res => 
      res.json()
      ), {
        refetchOnWindowFocus: false
      }
    )

    if (istokenExpired) return <Navigate to="/login" />
    if (isLoading) return "Loading..."
    if (error) return 'An error has occured: ' + error.message

    return (
        <>
        <TextToSpeech text={"Welcome back " + data.data.full_name.split(' ')[0]} />
        <section className=" bg-black body-font lg:pt-6 container mx-auto h-screen w-full max-w-full object-contain">
        <Navbar />
          <div className="bg-black text-white px-4 py-16 mx-auto w-full max-w-screen md:px-24 lg:px-8 lg:py-28">
            <div className="grid gap-24 row-gap-8 lg:grid-cols-5">
                <div className="grid gap-8 lg:col-span-2">
                <div>
                <p className="mb-2 text-2xl font-bold">Dashboard</p>
                    <p className="mb-2 text-lg font-bold">Welcome Back, {data ? data.data.full_name.split(' ')[0] : null} </p>
                    <p className="text-white">
                    Here is your profile summary
                    </p>
                </div>
                </div>
                <div className="grid border divide-y rounded lg:col-span-3 sm:grid-cols-2 sm:divide-y-0 sm:divide-x">
                <div className="flex flex-col justify-between p-5">
                    <div>
                    <p className="text-lg font-medium text-gray-400 sm:text-base">
                        Full Name
                    </p>
                    <p className="text-2xl font-bold text-white sm:text-xl">
                        {data.data.full_name}
                    </p>
                    </div>
                    <div>
                    <p className="text-lg font-medium text-gray-400 sm:text-base">
                        Sex
                    </p>
                    <p className="text-2xl font-bold text-white sm:text-xl">
                        {data.data.sex}
                    </p>
                    </div>
                    <div>
                    <p className="text-lg font-medium text-gray-400 sm:text-base">
                        Phone Number
                    </p>
                    <p className="text-2xl font-bold text-white sm:text-xl">
                        {data.data.phone_number}
                    </p>
                    </div>
                </div>
                <div className="flex flex-col  p-10">
                    <div>
                    <p className="text-lg font-medium text-gray-400 sm:text-base">
                        Email address
                    </p>
                    <p className="text-2xl font-bold text-white sm:text-xl">
                        {data.data.email}
                    </p>
                    </div>
                    <div>
                    <p className="text-lg font-medium text-gray-400 sm:text-base">
                        Country
                    </p>
                    <p className="text-2xl font-bold text-white sm:text-xl">
                        {data.data.country}
                    </p>
                    </div>
                </div>
                </div>
            </div>
            </div>  
            </section>
            <Footer />
        </>
    )
}

export default Profile