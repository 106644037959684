function SigninPassword({formData, setFormData}){
    return(
        <>
        <div className="container px-5 pt-32 mx-auto lg:px-4 lg:py-4">
                <div className="flex flex-col w-full mb-2 text-center sm:items-center space-y-4 ">
                <h1 className="text-lg font-bold tracking-tighter text-white lg:text-3xl md:text-xl">
                <span className="font-medium text-sm tracking-wide">{formData.email}</span>
                    <br></br>
                    Enter your password
                </h1>
                <br></br>
                <input 
                    type="password"
                    className="mx-auto text-center rounded-md lg:w-1/3"
                    onChange={(e) => {
                        setFormData({
                            ...formData,
                            password: e.target.value
                        })
                    }}
                    value={formData.password}>   
                </input>
                </div>
                
            </div>
        </>
    )
}

export default SigninPassword