import Footer from "../components/Footer"
import Hero from "../components/Hero"
import Navbar from "../components/Navbar"

function Landing(){
    return(
        <>
        <Navbar />
        <Hero />
        <Footer />
        </>
    )
}

export default Landing