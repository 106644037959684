import AudioRecorder from "../../components/AudioRecorder"

function FullName({formData, setFormData}){
    

    
    return(
        <>
        
            <div className="container px-5 pt-32 mx-auto lg:px-4 lg:py-4">
                <div className="flex flex-col w-full mb-2 text-center sm:items-center space-y-4 ">
                <h1 className="text-lg font-bold tracking-tighter text-white lg:text-3xl md:text-xl">
                <span className="font-medium text-sm tracking-wide">Create your account</span>
                    <br className=""></br>
                    <span className="font-medium text-sm tracking-wide">Step: 1/7</span>
                    <br className=""></br>
                    What is your full name
                </h1>
                <br></br>
                <input
                    type="text" 
                    placeholder="John Doe" 
                    className="mx-auto text-center rounded-md lg:w-1/3"
                    onChange={(e) => {
                        setFormData({
                            ...formData,
                            full_name: e.target.value
                        })
                    }}
                    value={formData.full_name}
                    required
                    >   
                </input> 
                <AudioRecorder 
                    formData={formData} 
                    setFormData={setFormData} 
                    page={0} 
                    />
                </div>
                
            </div>
        </>
    )
}

export default FullName